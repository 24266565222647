// vendors
import classNames from 'classnames';
import React from 'react';

import * as styles from './PageHeader.module.css';

export default function PageHeader({ children }) {
  return (
    <header className="bg-indigo h-[68vh]">
      <div className="container flex items-end h-full pb-24">
        <h1
          className={classNames([
            'font-sans uppercase text-white font-light',
            styles.title,
          ])}
        >
          {children}
        </h1>
      </div>
    </header>
  );
}
