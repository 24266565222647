import React from 'react';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

function ImageContainer({ image }) {
  return (
    <div className="mb-4 w-full">
      <GatsbyImage
        image={image}
        alt=""
        role="presentation"
        sizes="(max-width: 640px) 100vw, (max-width: 768px) 640px, (max-width: 1024px) 376px, (max-width: 1280px) 504px, (max-width: 1536px) 632px, 760px"
      />
    </div>
  );
}

export default function Gallery({ pictures = [], className }) {
  return (
    <div className={classNames([className])}>
      <div className="md:columns-2">
        {pictures.map(({ gatsbyImageData }, index) => (
          <ImageContainer
            key={`picture-${index}`}
            image={gatsbyImageData}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}
