import classNames from 'classnames';
import React from 'react';

const Heading = ({ children, level, as, className, ...props }) => {
  const Tag = as || `h${level}`;
  let classes = '';

  switch (level) {
    case 1:
      classes = `text-4xl font-sans font-light mb-8 mt-16`;
      break;

    case 2:
      classes = `text-5xl font-serif font-semibold mt-24 mb-12`;
      break;

    case 3:
      classes = `text-2xl font-serif font-semibold mb-8 mt-16`;
      break;

    case 4:
      classes = `text-xl font-serif font-semibold mb-4 mt-8`;
      break;

    default:
      break;
  }

  return (
    <Tag className={classNames(classes, className)} {...props}>
      {children}
    </Tag>
  );
};

export default Heading;
