import classNames from 'classnames';
import React from 'react';

export default function TestimonyCard({ fullName, text, address, className }) {
  return (
    <div
      className={classNames(['bg-white inline-block max-w-full', className])}
    >
      <div className="max-w-[100vw]">
        <div className="max-w-prose px-container py-8 before:content-['“'] before:block before:text-6xl before:text-yellow before:font-serif before:font-semibold before:italic">
          <div className="text-3xl text-yellow font-serif font-semibold italic mb-4 ">
            {React.cloneElement(text, { className: 'space-y-7' })}
          </div>

          <div className="flex before:content-[''] before:block before:h-px before:w-8 before:bg-indigo before:mt-3">
            <p className="uppercase ml-1">
              <span className="font-semibold">{fullName}</span>
              <br />
              {address}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
