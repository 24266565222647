import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import Gallery from '../components/Gallery/Gallery';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import TestimonyCarouselGrid from '../components/TestimonyCarouselGrid';
import BlockContent from '../components/BlockContent';
import getLocaleBlocks from '../../lib/getLocaleBlocks';

const translations = [
  {
    locale: 'fr',
    url: '/realisations/',
  },
];

export default function CreationsPage({
  data: { sanityCreationsPage },
  pageContext,
}) {
  const { langKey } = pageContext;

  const data = useMemo(() => {
    const description = (
      <BlockContent
        blocks={getLocaleBlocks(sanityCreationsPage.description, langKey)}
      />
    );

    const gallery =
      (sanityCreationsPage?.gallery?.images || []).map(({ image }) => {
        const gatsbyImageData = getImage(image.asset.localFile.childImageSharp);

        return {
          gatsbyImageData,
        };
      }) || [];

    return {
      description,
      gallery,
    };
  }, [
    langKey,
    sanityCreationsPage.description,
    sanityCreationsPage?.gallery?.images,
  ]);

  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>Creations</PageHeader>

        <div className="container text-xl text-center">
          <Heading level={2} className="text-5xl">
            It’s more than a guitar. <br />
            It’s a Michel Pellerin work of art.
          </Heading>

          {data.description && (
            <p className="max-w-prose mx-auto">
              {React.cloneElement(data.description)}
            </p>
          )}
        </div>

        {data.gallery?.length > 0 && (
          <section className="container">
            <h2 className="sr-only">Gallery</h2>
            <div className=" wide-content-contained">
              <Gallery pictures={data.gallery} />
            </div>
          </section>
        )}

        {/* TODO: add video */}
        {/* <section className="container">
          <div className="container text-xl text-center">
            <div>
              <Heading level={2} className="text-5xl">
                See videos of Pellerin guitars in action
              </Heading>
            </div>
          </div>

        </section> */}

        <section>
          <div className="container text-xl text-center">
            <div>
              <Heading level={2} className="text-5xl">
                Rave reviews of Pellerin guitars
              </Heading>
            </div>
          </div>

          <TestimonyCarouselGrid className="my-12" />

          <div className="container text-center">
            <UnderlinedButton to="/en/testimonials" className="text-xl my-5">
              More testimonials
            </UnderlinedButton>
          </div>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityCreationsPage {
      description {
        _rawEn(resolveReferences: { maxDepth: 10 })
        _rawFr(resolveReferences: { maxDepth: 10 })
      }
      gallery {
        images {
          image {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760)
                }
              }
            }
          }
        }
      }
    }
  }
`;
