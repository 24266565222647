import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

export default function UnderlinedButton({ children, className, ...props }) {
  let Tag = 'button';

  if (props.to) Tag = Link;
  if (props.href) Tag = 'a';
  if (props.tag) Tag = props.tag;

  return (
    <Tag
      {...props}
      className={classNames([
        'underline underline-offset-4 focus:decoration-4 hover:decoration-4 font-medium',
        className,
      ])}
    >
      {children}
    </Tag>
  );
}
