import classNames from 'classnames';
import { m as motion, useAnimation } from 'framer-motion';
import React, { useRef } from 'react';
import TestimonyCard from '../TestimonyCard';

export default function TestimonyCarousel({
  testimonials,
  speed = 1,
  className,
}) {
  const controls = useAnimation();
  const slidesRef = useRef(null);

  React.useEffect(() => {
    controls.start(() => ({
      x: [1, -1 * slidesRef.current.scrollWidth],
      transition: {
        repeat: Infinity,
        repeatType: 'loop',
        duration: slidesRef.current.scrollWidth * (0.025 / speed),
        ease: 'linear',
      },
    }));
  }, [speed, controls, slidesRef]);

  return (
    <div className={classNames(['overflow-hidden max-w-full', className])}>
      <motion.div className="flex will-change-transform" animate={controls}>
        <ul className="flex space-x-4 pr-4" ref={slidesRef}>
          {testimonials.map((testimony, index) => (
            <TestimonyCard
              {...testimony}
              key={`0-${index}`}
              className="min-w-max"
            />
          ))}
        </ul>
        <ul className="flex space-x-4">
          {[...testimonials, ...testimonials].map((testimony, index) => (
            <TestimonyCard
              {...testimony}
              key={`1-${index}`}
              className="min-w-max"
            />
          ))}
        </ul>
      </motion.div>
    </div>
  );
}
