import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useIntl } from 'react-intl';
import getLocaleBlocks from '../../../lib/getLocaleBlocks';
import getLocaleString from '../../../lib/getLocaleString';
import BlockContent from '../BlockContent';
import TestimonyCarousel from '../TestimonyCarousel';

const query = graphql`
  query {
    testimonies: allSanityTestimony {
      nodes {
        body {
          _rawFr(resolveReferences: { maxDepth: 10 })
          _rawEn(resolveReferences: { maxDepth: 10 })
        }
        fullName
        address {
          en
          fr
        }
      }
    }
  }
`;

export default function TestimonyCarouselGrid({ className }) {
  const { locale } = useIntl();

  const data = useStaticQuery(query);

  const testimonials = React.useMemo(
    () =>
      data.testimonies.nodes
        .map((node) => {
          const address = getLocaleString(node.address, locale);

          const blocks = getLocaleBlocks(node.body, locale) || [];

          if (blocks.length > 1) return null;

          const count = blocks[0].children
            .map(({ text = '' }) => text)
            .join(' ').length;

          if (count > 160) {
            return null;
          }

          let text = <BlockContent blocks={blocks} />;

          return {
            fullName: node.fullName,
            address,
            text,
          };
        })
        .filter((testimony) => testimony),
    [data, locale]
  );

  const testimonialsFristLine = testimonials.slice(0, testimonials.length / 2);
  const testimonialsSecondLine = testimonials.slice(
    testimonials.length / 2,
    testimonials.length
  );

  return (
    <div className={classNames(['space-y-4', className])}>
      <TestimonyCarousel testimonials={testimonialsFristLine} />

      <TestimonyCarousel
        testimonials={testimonialsSecondLine}
        speed={1.125}
        className="hidden lg:block"
      />
    </div>
  );
}
