export default function getLocaleString(localizedSting, locale) {
  if (!localizedSting) return '';
  switch (locale) {
    case 'en':
      return localizedSting.en || localizedSting.fr;
    case 'fr':
      return localizedSting.fr || localizedSting.en;
    default:
      return localizedSting.fr || localizedSting.en;
  }
}
